<template>
  <div>
    <h1 class="page-header">Dashboard <small></small></h1>
    <div class="row">
      <div class="col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon"><i class="fa fa-users"></i></div>
          <div class="stats-info">
            <h4>JUMLAH MAHASISWA AKTIF</h4>
            <p>{{ j_soal }}</p>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="widget widget-stats bg-green">
          <div class="stats-icon"><i class="fa fa-users"></i></div>
          <div class="stats-info">
            <h4>JUMLAH DOSEN</h4>
            <p>{{ j_review }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-info">
          <div class="stats-icon"><i class="fa fa-list-alt"></i></div>
          <div class="stats-info">
            <h4>JUMLAH SOAL</h4>
            <p>{{ j_soal }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-lime">
          <div class="stats-icon"><i class="fa fa-tasks"></i></div>
          <div class="stats-info">
            <h4>JUMLAH SUDAH DIREVIEW</h4>
            <p>{{ j_review }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-warning">
          <div class="stats-icon"><i class="fa fa-list"></i></div>
          <div class="stats-info">
            <h4>JUMLAH BELUM DIREVIEW</h4>
            <p>{{ j_unreview }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-danger">
          <div class="stats-icon"><i class="fa fa-book"></i></div>
          <div class="stats-info">
            <h4>JUMLAH PAKET SOAL</h4>
            <p>{{ j_paket }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userData from "../plugins/userData";

export default {
  mixins: [userData],
  name: "DashboardPage",
  data() {
    return {
      j_soal: 0,
      j_paket: 0,
      j_review: 0,
      j_unreview: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            "X-Token": this.userToken,
          },
        })
        .then((response) => {
          this.kunjungan_hari = response.data.kunjungan_hari;
          this.kunjungan_bulan = response.data.kunjungan_bulan;
          this.kunjungan_tahun = response.data.kunjungan_tahun;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>