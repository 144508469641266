import Vue from "vue";

import router from "./router";
import "./plugins/axios";

// plugins
import VueBootstrap from "bootstrap-vue";
import VueNVD3 from "vue-nvd3";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueGoodTable from "vue-good-table";
import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
import DateRangePicker from "vue2-daterange-picker";
import { VueEditor } from "vue2-editor";

// plugins css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap-social/bootstrap-social.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "quill/dist/quill.snow.css";

// color admin css
import "./scss/vue.scss";

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueBootstrap);
Vue.use(VueEditor);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, {
  locale: "en",
});
Vue.use(VueSparkline);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
Vue.use(VueMaskedInput);
Vue.use(VueColorpicker);
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("date-range-picker", DateRangePicker);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
