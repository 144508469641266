import Vue from "vue";
import VueRouter from "vue-router";
import { jwtDecode } from "jwt-decode";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    component: () => import("../pages/NotFound.vue"),
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/forbidden",
    component: () => import("../pages/Forbidden.vue"),
  },
  {
    path: "/pengumuman",
    component: () => import("../pages/pengumuman/List.vue"),
  },
  {
    path: "/paket-soal/list-paket",
    component: () => import("../pages/paket-soal/List.vue"),
  },
  {
    path: "/paket-soal/buat-paket",
    component: () => import("../pages/paket-soal/Form.vue"),
  },
  {
    path: "/soal/buat-soal",
    component: () => import("../pages/soal/Form.vue"),
  },
  {
    path: "/soal/belum-submit",
    component: () => import("../pages/soal/Created.vue"),
  },
  {
    path: "/soal/sudah-submit",
    component: () => import("../pages/soal/Submitted.vue"),
  },
  {
    path: "/review-soal/belum-review",
    component: () => import("../pages/review-soal/Unreview.vue"),
  },
  {
    path: "/review-soal/sudah-review",
    component: () => import("../pages/review-soal/Reviewed.vue"),
  },
  {
    path: "/akademisi/dosen",
    component: () => import("../pages/dosen/List.vue"),
  },
  {
    path: "/akademisi/mahasiswa",
    component: () => import("../pages/mahasiswa/List.vue"),
  },
  {
    path: "/master/blok-mk/list",
    component: () => import("../pages/master/blok/List.vue"),
  },
  {
    path: "/master/skdi/list",
    component: () => import("../pages/master/skdi/List.vue"),
  },
  {
    path: "/master/sistem-penyakit/list",
    component: () => import("../pages/master/sistem-penyakit/List.vue"),
  },
  {
    path: "/master/tinjauan/list",
    component: () => import("../pages/master/tinjauan/List.vue"),
  },
  {
    path: "/master/sub-tinjauan/list",
    component: () => import("../pages/master/sub-tinjauan/List.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("../pages/pengguna/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/pengguna/Form.vue"),
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/pengguna/Edit.vue"),
  },
  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const userData = localStorage.getItem("userData");
  let userLevel = null;

  if (userData) {
    const userAceess = JSON.parse(userData);
    const decodedToken = jwtDecode(userAceess.token);
    userLevel = decodedToken ? decodedToken.role : null;
  }

  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !userData) {
    next("/login");
  } else if (to.path === "/login" && userData) {
    next("/dashboard");
  } else if (requiredLevel && userLevel !== requiredLevel) {
    next("/forbidden");
  } else {
    next();
  }
});

export default router;
